'use client'

import { XMarkIcon } from '@heroicons/react/24/solid'
import { Button, Spinner, Tooltip } from '@material-tailwind/react'
import { Message, useChat } from 'ai/react'
import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { useEffect, useMemo, useRef, useState } from 'react'

import { useAvaSidebar } from '@/providers/AvaSidebarProvider'
import { useChatbot } from '@/providers/ChatbotProvider'
import { useRouterHistoryContext } from '@/providers/RouterHistoryProvider'
import avaIcon from '@/public/images/ava-icon.png'

import { UserRoles } from '@roadmap/lib/services/firebase/firestore/enums'

import ChatInput from '../Chat/ChatInput'
import { useClientConfig } from '../Chat/useConfig'
import AutowelcomeMessage from './AutowelcomeMessage'
import AvaConversationState from './AvaConversationState'
import AvaInitialState from './AvaInitialState'
import ReachedLimitState from './ReachedLimitState'
import RecLetterState from './RecLetterState'

const AvaSidebar = () => {
  const { update, data: session } = useSession()
  const { isOpen, shouldShowWelcomeMessage, toggleSidebar, closeSidebar } = useAvaSidebar()
  const [sidebarWidth, setSidebarWidth] = useState(0)
  const [activeContent, setActiveContent] = useState<string>('avaInitial')
  const [showModal, setShowModal] = useState(false)
  const sidebarRef = useRef<HTMLDivElement>(null)
  const t = useTranslations('AskAva')
  const hasReachedLimit = useMemo(() => {
    return session?.user.aiRateLimited
  }, [session?.user.aiRateLimited])
  const [history, setHistory] = useState<Message[]>([])
  const { data } = useSession()
  const userRole = useMemo(() => {
    return data?.user.role
  }, [data])
  const { chatId, initialPrompt, refreshChat, isLoading: isChatLoading, setInitialPrompt } = useChatbot()
  const { chatAPI } = useClientConfig()
  const [isCardClicked, setIsCardClicked] = useState<boolean>(false)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth
      setWidth(newWidth)
    }
    window.addEventListener('resize', updateWindowDimensions)
    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  useEffect(() => {
    if (sidebarRef.current) {
      setSidebarWidth(sidebarRef.current.getBoundingClientRect().width)
    }
  }, [isOpen, width])

  const { messages, input, isLoading, handleSubmit, handleInputChange, reload, stop, append, setInput } = useChat({
    api: `${chatAPI}/stream/${chatId}`,
    id: chatId,
    headers: {
      'Content-Type': 'application/json',
    },
    onError: (error: unknown) => {
      if (!(error instanceof Error)) throw error
      console.log(error)
      const message = JSON.parse(error.message)
      alert(message.detail)
    },
    onFinish: async (message, { usage }) => {
      // `usage` object is documented here: https://sdk.vercel.ai/docs/reference/ai-sdk-ui/use-chat
      // - POST update to /api/chatbot/usage
      // - update session
      const response = await fetch('/api/chatbot/usage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(usage),
      })
      if (response.ok) {
        const body = await response.json()
        await update(body)
      }
    },
    initialMessages: history,
  })
  useEffect(() => {
    if (!!initialPrompt) {
      toggleSidebar(false)
      if (!isChatLoading && !hasReachedLimit) {
        setInput(initialPrompt)
        setIsCardClicked(true)
        setInitialPrompt('')
      }
    }
  }, [hasReachedLimit, initialPrompt, isChatLoading, setInitialPrompt, setInput, toggleSidebar])

  const refreshAvaContent = () => {
    refreshChat()
    setActiveContent('avaInitial')
    setHistory([])
  }

  const handleCloseSidebar = () => {
    if (messages.length > 0) setShowModal(true)
    else closeSidebar()
  }
  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      closeSidebar()
      refreshAvaContent()
    }
    setShowModal(false)
  }
  const renderContent = () => {
    if (hasReachedLimit) return <ReachedLimitState />
    switch (activeContent) {
      case 'avaInitial':
        return (
          <AvaInitialState
            onCardSelected={(cardContent) => {
              setInput(cardContent)
              setIsCardClicked(true)
              setActiveContent('avaConversation')
            }}
          />
        )
      case 'avaConversation':
        return (
          <AvaConversationState
            chatId={chatId || ''}
            messages={messages}
            reload={reload}
            append={append}
            stop={stop}
            isLoading={isLoading}
          />
        )
      default:
        return null
    }
  }
  // Handling on prompt card click
  useEffect(() => {
    if (isCardClicked && !isChatLoading && !hasReachedLimit) {
      handleSubmit()
      setIsCardClicked(false)
      setActiveContent('avaConversation')
    }
  }, [isCardClicked, isChatLoading, hasReachedLimit, handleSubmit])

  const { previousPath } = useRouterHistoryContext()

  useEffect(() => {
    if (previousPath?.includes('/onboarding') && shouldShowWelcomeMessage === undefined && !hasReachedLimit) {
      toggleSidebar(true)
    }
  }, [previousPath, hasReachedLimit, shouldShowWelcomeMessage, toggleSidebar])

  const [shouldShowRecLetterState, setShouldShowRecLetterState] = useState(false)
  const handleClickOnComposeARecommendationLetter = () => {
    setShouldShowRecLetterState(true)
  }
  return (
    <div>
      <button
        id="avaSidebar"
        onClick={() => (isOpen ? closeSidebar() : toggleSidebar())}
        className="fixed top-1/3 z-50 transition-all duration-300 
          px-6 py-2 bg-brand-gold flex flex-col items-center rounded-l-2xl"
        style={{ right: isOpen ? `${sidebarWidth}px` : '0px' }}
      >
        <div className="h-9 w-6 relative">
          <Image src={avaIcon} layout="fill" objectFit="contain" alt="Ava Icon" />
        </div>
        <span className="text-xs font-bold uppercase mt-0.5">Ava</span>
      </button>
      {/* Sidebar */}
      <div
        ref={sidebarRef}
        className={`fixed pb-5 top-0 right-0 w-full md:w-4/6 lg:w-3/5 xl:w-1/2 2xl:w-5/12 h-screen
          bg-brand-tan text-gray-900 transform ${
            isOpen ? 'translate-x-0' : 'translate-x-full'
          } transition-transform duration-300 z-40 flex flex-col`}
      >
        <div className="flex items-center justify-between p-3 bg-brand-purple-800">
          <div className="flex items-center space-x-2">
            <div className="relative w-[22px] h-[36px]">
              <Image src={avaIcon} layout="fill" objectFit="contain" alt="Ava Icon" />
            </div>
            <h2 className="text-xl font-bold text-white px-2">{t('askAva')}</h2>
          </div>
          <Tooltip content={t('closeTooltip')} placement="bottom">
            <Button
              onClick={handleCloseSidebar}
              className="p-2 font-bold text-white cursor-pointer bg-transparent hover:shadow-none shadow-none rounded-full"
            >
              <XMarkIcon className="h-7 w-7" />
            </Button>
          </Tooltip>
        </div>
        {shouldShowRecLetterState ? (
          <RecLetterState removeRecLetterState={() => setShouldShowRecLetterState(false)} />
        ) : (
          <div className="flex-grow overflow-y-auto p-2 md:p-4">
            <div className="px-3 md:px-6 w-full h-full">
              {shouldShowWelcomeMessage ? (
                <AutowelcomeMessage onLetsStartClick={() => toggleSidebar()} />
              ) : isChatLoading ? (
                <div className="w-full h-full min-h-full flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                <div className="pb-4">{renderContent()}</div>
              )}
            </div>
          </div>
        )}
        {/* Search */}
        {!shouldShowRecLetterState && !hasReachedLimit && (
          <>
            <ChatInput
              placeholder={t('askAvaPromptPlaceholder')}
              input={input}
              handleInputChange={(e) => {
                handleInputChange(e)
              }}
              handleSubmit={(e) => {
                handleSubmit(e)
                if (activeContent === 'avaInitial') setActiveContent('avaConversation')
              }}
              isLoading={isLoading}
              messages={messages}
              append={append}
              setInput={setInput}
            />
            {userRole === UserRoles.COUNSELOR && (
              <div className="px-9 py-3">
                <button
                  className="py-1 px-2 text-white bg-brand-purple rounded-2xl cursor-pointer 
                  shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 
                  focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none 
                  w-auto font-sans text-base"
                  onClick={handleClickOnComposeARecommendationLetter}
                >
                  <p>{t('composeRecLetter')}</p>
                </button>
              </div>
            )}
          </>
        )}
      </div>
      {/* Overlay */}
      {isOpen && <div onClick={() => closeSidebar()} className="fixed inset-0 bg-black opacity-20 z-30" />}
      {/* Confirmation Modal */}
      {showModal && (
        <div className="fixed inset-0 top-24 flex items-start justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg mx-2 p-3 md:p-6 max-w-sm md:max-w-lg w-full">
            <h3 className="text-lg font-semibold mb-4">{t('confirmation')}</h3>
            <p className="text-gray-900 mb-4">
              <span className="text-red-500">{t('closingAvaPanelHistory')}</span>
              <br />
              {t('closingAvaPanelCopy')}
              <br />
              {t('useAvaSessionLater')}
            </p>
            <div className="flex justify-end space-x-4">
              <Button
                onClick={() => confirmClose(false)}
                className="px-4 py-4 bg-white border-gray-800 text-gray-900 rounded-lg"
              >
                {t('cancel')}
              </Button>
              <Button
                onClick={() => confirmClose(true)}
                className="px-4 py-4 bg-brand-purple-700 text-white rounded-lg"
              >
                {t('closeAvaPanel')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default AvaSidebar
